$status-approved-border: $state-success-border !default;
$status-approved-text: $state-success-text !default;

$status-denied-border: $state-danger-border !default;
$status-denied-text: $state-danger-text !default;

$status-draft-border: $gray !default;
$status-draft-text: $status-draft-border !default;

$status-expired-border: $state-warning-border !default;
$status-expired-text: $state-warning-text !default;

$status-pending-border: $state-info-border !default;
$status-pending-text: $state-info-text !default;

$status-scheduled-border: $state-info-bg !default;
$status-scheduled-text: $state-info-text !default;