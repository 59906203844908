/* ---------- Help messages, general additional information, etc. ---------- */

.portlet-msg-alert, .portlet-msg-error, .portlet-msg-help, .portlet-msg-info, .portlet-msg-progress, .portlet-msg-success {
	@extend .alert;
}

.portlet-msg-help, .portlet-msg-info, .portlet-msg-progress {
	@extend .alert-info;
}

.portlet-msg-progress {
	background-image: url(../images/aui/loading_indicator.gif);
	background-position: 6px 50%;
	background-repeat: no-repeat;
	padding-left: 30px;
}

/* ---------- Error messages ---------- */

.portlet-msg-error {
	@extend .alert-danger;
}

/* ---------- Alert messages ---------- */

.portlet-msg-alert {
	@extend .alert-warning;
}

/* ---------- Success messages ---------- */

.portlet-msg-success {
	@extend .alert-success;
}

/* ---------- Misc ---------- */

.navbar .nav li > a {
	@include word-wrap(break-all);
}

/* ---------- Status ---------- */

.entry-status {
	&.approved {
		border-color: $status-approved-border !important;
	}

	&.scheduled {
		border-color: $status-scheduled-border !important;
	}

	&.pending {
		border-color: $status-pending-border !important;
	}

	&.denied {
		border-color: $status-denied-border !important;
	}

	&.expired {
		border-color: $status-expired-border !important;
	}

	&.draft {
		border-color: $status-draft-border !important;
	}
}

.status {
	&.approved {
		color: $status-approved-text;
	}

	&.scheduled {
		color: $status-scheduled-text;
	}

	&.pending {
		color: $status-pending-text;
	}

	&.denied {
		color: $status-denied-text;
	}

	&.expired {
		color: $status-expired-text;
	}

	&.draft {
		color: $status-draft-text;
	}
}